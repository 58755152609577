import * as React from "react"
import styled from "styled-components"
import { Button } from "../../atoms/button"

export const QuestionForm = () => {
  return (
    <Container>
      <Form
        method="POST"
        netlify-honeypot="field"
        data-netlify="true"
        name="contact"
        action="/contact/success"
      >
        <input type="hidden" name="form-name" value="contact" />

        <div>
        <FloatingLabel for="question">question</FloatingLabel>
          <QuestionInput
            name="question"
            placeholder="Ask away..."
            aria-label="Your Question"
            required
          />
        </div>

        <div>
          <div>
            <FloatingLabel for="name">name</FloatingLabel>
            <Input
              type="name"
              name="name"
              placeholder="Your name here..."
              aria-label="Your name"
              required
            />
          </div>

          <div>
            <FloatingLabel for="email">email</FloatingLabel>
            <Input
              type="email"
              name="email"
              placeholder="Your email here..."
              aria-label="Your email address"
              required
            />
          </div>

          <div>
            <FloatingLabel for="telephone">telephone</FloatingLabel>
            <Input
              type="tel"
              name="phone"
              placeholder="Your phone number here..."
              aria-label="Your phone number"
              required
            />
          </div>

          <Label id="sub-check">
            <Input type="checkbox" name="subscribe" />
            <p style={{ lineHeight: "1.25rem", paddingLeft: "1rem" }}>I would like to subscribe to Who Studio’s mailing list</p>
          </Label>
        </div>

        <p style={{ visibility: "hidden", height: 0 }}>
          <label>
            Don’t fill this out if you're human: <input name="field" />
          </label>
        </p>

        <FormButton type="submit">send</FormButton>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  background-color: var(--white);
  padding: 0 25px;
  margin-bottom: 1.75rem;

  @media (min-width: 768px) {
    padding: 0 75px;
    margin-bottom: 2.75rem;
  }
`

const Form = styled.form`
  #sub-check {
    display: grid;
    grid-template-columns: 25px 1fr;
    justify-self: start;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin-left: 2.5px;
      margin-bottom: 1rem;
    }
  }

  #sub-check span {
    font: 1rem/1rem var(--body-font);
    color: var(--brand-green);
    padding: 0.5rem 1rem;

    @media (min-width: 768px) {
      padding: 0.5rem 2rem;
    }
  }

  #sub-check input {
    height: 20px;
    width: 20px;
    appearance: none;
    border: 1px solid #34495e;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: var(--white);
    cursor: pointer;

    @media (min-width: 768px) {
      height: 30px;
      width: 30px;
    }
  }

  #sub-check input:checked {
    background-color: var(--brand-green);
    box-shadow: 0px 0px 2px 1px white inset;
  }

  input,
  textarea {
    width: 100%;
    padding: 0rem;
    border-radius: 5px;
    border: 1px solid var(--brand-green);
    margin-bottom: 25px;
    font: 1rem/1em var(--body-font);
    padding: .35rem;

    @media (min-width: 768px) {
    }
  }

  div {
    width: 100%;
  }
  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
`

const FloatingLabel = styled.label`
  display:block;
  transform: translate(.5rem,.5rem);
  background-color: var(--white);
  width: fit-content;
  font-family: var(--body-font);
  font-variant: small-caps;
  text-transform: lowercase;
  color: var(--primary);
  text-align:left;
  justify-self: start;
`

const FormButton = styled(Button)`
  width: 80%;
  padding: 1rem 0;
  @media (min-width: 768px) {
    margin: 0;
    width: 100%;
    justify-self: start;
  }
`

const Label = styled.label`
  align-self: start;
  justify-self:start;
  line-height: 1.5rem;
`

const Input = styled.input``

const QuestionInput = styled.textarea`
  height: 111px;
  border-radius: 5px;

  @media (min-width: 768px) {
    height: 100%;
    order: -1;
    grid-row: 1/7;
  }
`
