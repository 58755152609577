import * as React from "react"
import styled from "styled-components"
import { Header, SubHeader } from "../shared/type"

export const Contact = (): JSX.Element => {
  const whoContactEmail = `hello@whomediastudio.com`
  return (
    <>
      <Container>
        <Heading>Contact Us</Heading>
        <hr />
        <Email
          href={`mailto:${whoContactEmail}?Subject=A%20question%20for%20you.`}
        >
          <SubHeader style={{ fontFamily: "var(--body-font)"}}>{whoContactEmail}</SubHeader>
        </Email>
        <Phone href="tel:1-604-363-9697"><SubHeader style={{ fontFamily: "var(--body-font)"}}>1-604-363-9697</SubHeader></Phone>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  background-color: var(--background);
  padding: 2rem 50px 1.5rem 50px;

  > a {
    justify-self: start;
    margin-bottom: 1rem;
  }

  > hr {
    border: 1px var(--brand-green) solid;
    margin-bottom: 2.5rem;
  }

  @media (min-width: 768px) {
    grid-column: 2;
    grid-template-columns: 1fr 1px 1fr;
    padding: 2rem 0;
    grid-gap: 0px 50px;
    justify-items: center;

    > a {
      grid-column: 3;
      justify-self: start;
      font: 400 1.4rem/1.8rem var(--body-font);

      @media (min-width: 768px) {
        padding: 0.5rem 0;
        margin: 0;
        align-self: center;
      }
    }

    > hr {
      grid-row: 1/3;
      border: 1px var(--brand-green) solid;

      @media (min-width: 768px) {
        margin: 0;
      }
    }
  }
`

const Heading = styled(Header)`
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    justify-self: end;
    align-self: center;
    grid-row: 1/3;
    margin-bottom: 0;
  }
`

const Email = styled.a`
`
const Phone = styled.a`
  font: 400 1.1rem/1.3rem var(--numeric);
`
